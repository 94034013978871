<template>
    <div class="short-chart">
        <short-line-chart
            :chart-data="chartData"
            :style="chartStyle"
            v-if="balanceStatistic"
            :suggested-max="suggestedMax"
            :suggested-min="suggestedMin"
        />
    </div>
</template>

<script>
import { defaultChartColors } from 'Config/portfolio';

export default {
    name: 'CurrentBalanceShortChart',
    props: {
        balance: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            assetSymbol: null,

            chartStyle: {
                width: '100px',
                height: '35px',
                'margin-top': '-2px',
            },
        };
    },
    computed: {
        balanceStatistic() {
            return this.assetSymbol ? this.$store.getters['Portfolio/shortBalanceStatisticByAssetSymbol'](this.assetSymbol) : null;
        },

        manifest() {
            return this.balanceStatistic ? this.balanceStatistic.assetManifest : null;
        },

        chartColors() {
            return this.balanceStatistic ? this.balanceStatistic.chartColors : defaultChartColors;
        },
        chartLineColor() {
            return this.chartColors.line;
        },
        chartBackgroundColor() {
            return this.chartColors.background;
        },
        chartLabels() {
            return this.balanceStatistic ? Object.entries(this.balanceStatistic.records) : [];
        },
        chartDatasetData() {
            // eslint-disable-next-line no-unused-vars
            return this.balanceStatistic ? Object.entries(this.balanceStatistic.records).map(([, { quoted }]) => quoted) : [];
        },
        allDataIsTheSame() {
            return this.chartDatasetData.every((value, index, records) => index === 0 || records[index - 1] === value);
        },
        suggestedMax() {
            if (this.allDataIsTheSame) {
                return this.chartDatasetData[0] === 0 ? 1 : this.chartDatasetData[0] * 2;
            }
            return null;
        },
        suggestedMin() {
            if (this.allDataIsTheSame && this.chartDatasetData[0] === 0) {
                return 0;
            }
            return null;
        },
        chartData() {
            return {
                labels: this.chartLabels,
                datasets: [
                    {
                        backgroundColor: this.chartBackgroundColor,
                        borderColor: this.chartLineColor,
                        lineTension: 0,
                        borderWidth: 2,
                        pointRadius: 0,
                        pointHitRadius: 0,
                        data: this.chartDatasetData,
                    },
                ],
            };
        },
    },
    mounted() {
        this.assetSymbol = this.balance.assetSymbol;
    },
};
</script>
