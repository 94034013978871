
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { assetsTypes } from 'Store/v2/Assets';
import Balance from 'Entities/privatePresenter/Balance';
import NoData from 'UI/NoData.vue';
import BlockHeader from 'UI/BlockHeader.vue';
import Pagination from 'Control/Pagination.vue';
import HeaderSwitcher from 'Control/HeaderSwitcher.vue';
import { IAggregatedBalance, IPlacementsAggregatedBalance } from 'Store/v2/Balances';

import BalancesList from './components/BalancesList.vue';

interface Props {
    showShortGraph?: boolean;
    isWorkspace?: boolean;
}

interface Data {
    searchQuery: string;
    assetTypeFilter: number;
    page: number;
    balancesPerPage: number;
    activeBalancesVariant: number;
}

interface Methods {
    depositFunds: () => void;
    filterBySearchQuery: (data: { assetSymbol: string }) => boolean;
    filterByType: (data: { assetType: string }) => boolean;
    switchTab: (data: number) => void;
}

interface Computed {
    balances: IAggregatedBalance[];
    activeAccountId: string;
    currenciesTypes: { name: string, type: null | string }[];
    activeCurrencyTypeFilter: { name: string, type: null | string };
    totalPages: number;
    paginatedBalances: Balance[];
    placementAggregatedBalances: IPlacementsAggregatedBalance[];
}

export default Vue.extend<Data, Methods, Computed, Props>({
    name: 'CurrentBalance',
    components: {
        BalancesList,
        NoData,
        Pagination,
        HeaderSwitcher,
        BlockHeader,
    },
    props: {
        showShortGraph: {
            type: Boolean,
            default: false,
        },
        isWorkspace: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            searchQuery: '',
            assetTypeFilter: 0,
            page: 1,
            balancesPerPage: 8,
            balancesViewVariants: ['assets', 'exchanges'],
            activeBalancesVariant: 0,
        };
    },
    computed: {
        ...mapGetters({
            balances: 'Balances/GET_AGGREGATED_BALANCES',
            placementAggregatedBalances: 'Balances/GET_PLACEMENTS_AGGREGATED_BALANCES',
        }),
        activeAccountId() {
            return this.$store.state.Accounts.activeAccountID;
        },
        currenciesTypes() {
            return [{
                name: 'All Currencies',
                type: null,
            }, ...Object.values(assetsTypes).map((type) => ({
                name: this.$store.getters.localization.Body.Terminal.AccountPortfolio.BalancesTypes[type].Name,
                type,
            }))];
        },
        activeCurrencyTypeFilter() {
            return this.currenciesTypes[this.assetTypeFilter];
        },
        totalPages() {
            let balances;
            if (this.activeBalancesVariant === 0) {
                balances = this.balances;
            } else {
                balances = this.placementAggregatedBalances;
            }
            return Math.floor(balances.length / 8);
        },
        paginatedBalances() {
            let balances;
            if (this.activeBalancesVariant === 0) {
                balances = this.balances;
                balances.sort(({ totalEquivalent: a }, { totalEquivalent: b }) => b - a);
            } else {
                balances = this.placementAggregatedBalances;
                balances.sort(({ placementName: firstPlacementName, assetsBalances: firstBalances }, { placementName: secondPlacementName, assetsBalances: secondBalances }) => {
                    if (firstPlacementName === 'Single Broker') {
                        return -1;
                    }
                    if (secondPlacementName === 'Single Broker') {
                        return 1;
                    }
                    const firstSummaryBalance = firstBalances.reduce((accum, { totalEquivalent }) => accum + totalEquivalent, 0);
                    const secondSummaryBalance = secondBalances.reduce((accum, { totalEquivalent }) => accum + totalEquivalent, 0);
                    return secondSummaryBalance - firstSummaryBalance;
                });
            }
            return balances.filter((_, index) => ((index + 1) < (this.balancesPerPage + 1) * this.page && (index + 1) > (this.page - 1) * (this.balancesPerPage + 1)));
        },
    },
    methods: {
        depositFunds() {
            this.$store.dispatch('Transactions/backLightWalletsPaymentModule');
        },
        filterBySearchQuery({ assetSymbol }) {
            return !this.searchQuery || !!~assetSymbol.toLowerCase().indexOf(this.searchQuery.toLowerCase());
        },
        filterByType({ assetType }) {
            return !this.activeCurrencyTypeFilter.type || assetType === this.activeCurrencyTypeFilter.type;
        },
        switchTab(e) {
            this.activeBalancesVariant = e;
        },
    },
    mounted() {
        this.$store.dispatch('Portfolio/remountData');
    },
    watch: {
        activeBalancesVariant() {
            this.page = 1;
        },
    },
});
