<template>
    <div :class="s.container">
        <div v-if="isAggregatedByPlacements" :class="s.col">
            <CryptoIcon :symbol="placementName" :size="14" />
            <span :class="s.smallText">{{ placementName }}</span>
            <span v-if="balance.blockchainName" :class="s.smallText">{{ balance.blockchainName }}</span>
        </div>
        <div v-else :class="s.col">
            <PlacementIcon :placement="placementName" :size="14" />
            <span :class="s.smallText">{{ placementName.split(':')[0] }}</span>
            <span v-if="placementName.split(':')[1]" :class="s.smallText">{{ placementName.split(':')[1] }}</span>
        </div>
        <div :class="s.col">
            <div :class="s.row">
                <span :class="[s.smallText, s.green]">{{ freeEquivalent.toFixed(balancePrecision).getSeparatedDigits() }}</span>
                <span :class="s.smallText"> ● </span>
                <span :class="[s.smallText, s.red]">{{ holdEquivalent.toFixed(balancePrecision).getSeparatedDigits() }}</span>
            </div>
            <span :class="s.bigText">{{ quotationAssetCharacter }}{{ totalEquivalent.toFixed(balancePrecision).getSeparatedDigits() }}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';

export default {
    name: 'PlacementBalancePopover',
    props: {
        balance: {
            type: Object,
            required: true,
        },
        placementName: {
            type: String,
            required: true,
        },
        isAggregatedByPlacements: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetPrecision: 'Assets/GET_QUOTATION_ASSET_PRECISION',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        balancePrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
        totalEquivalent() {
            if (this.isAggregatedByPlacements) {
                return this.balance.totalEquivalent;
            }
            return this.$store.getters['Balances/GET_BALANCE_TOTAL_EQUIVALENT'](this.balance) || 0;
        },
        freeEquivalent() {
            if (this.isAggregatedByPlacements) {
                return this.balance.freeEquivalent;
            }
            return this.$store.getters['Balances/GET_BALANCE_FREE_EQUIVALENT'](this.balance) || 0;
        },
        holdEquivalent() {
            if (this.isAggregatedByPlacements) {
                return this.balance.holdEquivalent;
            }
            return this.$store.getters['Balances/GET_BALANCE_SUMMARY_HOLD_EQUIVALENT'](this.balance) || 0;
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    display: flex;
    justify-content: space-between;
    column-gap: var(--m-l);
}
.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.col {
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: center;
    align-items: center;
    row-gap: var(--m-xs);
}
.smallText {
    font-weight: var(--fw-bold);
    font-size: var(--fs-xxs);
    line-height: var(--fs-xxs);
    color: var(--cl-gray-strong);
    &.green {
        color: var(--cl-green-strong);
        margin-right: var(--m-xs);
    }
    &.red {
        color: var(--cl-red-strong);
        margin-left: var(--m-xs);
    }
}
.bigText {
    font-weight: var(--fw-semibold);
    font-size: var(--fs-l);
    line-height: var(--fs-l);
    text-transform: uppercase;
    color: var(--cl-black);
}
</style>
