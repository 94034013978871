<template>
    <div>
        <div
            :class="s.container"
            v-for="(placement, index) in placements"
            :key="`placemebtBalance${index}`"
        >
            <div v-if="isAggregatedByPlacements" :class="s.col">
                <CryptoIcon :symbol="placement.assetSymbol" :size="14" />
                <span :class="s.smallText">{{ placement.assetSymbol }}</span>
                <span v-if="placement.blockchainName" :class="s.smallText">{{ placement.blockchainName }}</span>
            </div>
            <div v-else :class="s.col">
                <PlacementIcon :placement="getPlacementName(placement.balance.placementId)" :size="14" />
                <span :class="s.smallText">{{ getPlacementName(placement.balance.placementId) }}</span>
                <span v-if="placement.placement.indexOf('Single Broker') !== -1" :class="s.smallText">{{ placement.placement.split(':')[1] }}</span>
            </div>
            <div :class="s.col">
                <div :class="s.row">
                    <span :class="[s.smallText, s.green]">{{ freeEquivalents[index].toFixed(quotationAssetPrecision).getSeparatedDigits() }}</span>
                    <span :class="s.smallText"> ● </span>
                    <span :class="[s.smallText, s.red]">{{ holdEquivalents[index].toFixed(quotationAssetPrecision).getSeparatedDigits() }}</span>
                </div>
                <span :class="s.bigText">{{ quotationAssetCharacter }}{{ totalEquivalents[index].toFixed(quotationAssetPrecision).getSeparatedDigits() }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'AllPlacementsBalancePopover',
    props: {
        placements: {
            type: Array,
            required: true,
        },
        isAggregatedByPlacements: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            getPlacementName: 'Placements/getPlacementNameById',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetPrecision: 'Assets/GET_QUOTATION_ASSET_PRECISION',
        }),
        totalEquivalents() {
            if (this.isAggregatedByPlacements) {
                return this.placements.map((b) => b.totalEquivalent);
            }
            return this.placements.map((b) => this.$store.getters['Balances/GET_BALANCE_TOTAL_EQUIVALENT'](b.balance) || 0);
        },
        holdEquivalents() {
            if (this.isAggregatedByPlacements) {
                return this.placements.map((b) => b.holdEquivalent);
            }
            return this.placements.map((b) => this.$store.getters['Balances/GET_BALANCE_SUMMARY_HOLD_EQUIVALENT'](b.balance) || 0);
        },
        freeEquivalents() {
            if (this.isAggregatedByPlacements) {
                return this.placements.map((b) => b.freeEquivalent);
            }
            return this.placements.map((b) => this.$store.getters['Balances/GET_BALANCE_FREE_EQUIVALENT'](b.balance) || 0);
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: var(--m-l);
    padding: var(--m-s) 0;
    border-bottom: 1px solid var(--cl-gray-light);
    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
    &:first-child {
        padding-top: 0;
    }
}
.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.col {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    row-gap: var(--m-xs);
}
.smallText {
    font-weight: var(--fw-bold);
    font-size: var(--fs-xxs);
    line-height: var(--fs-xxs);
    color: var(--cl-gray-strong);
    &.green {
        color: var(--cl-green-strong);
        margin-right: var(--m-xs);
    }
    &.red {
        color: var(--cl-red-strong);
        margin-left: var(--m-xs);
    }
}
.bigText {
    font-weight: var(--fw-semibold);
    font-size: var(--fs-l);
    line-height: var(--fs-l);
    text-transform: uppercase;
    color: var(--cl-black);
}
</style>
