<template>
    <div :class="[theme.quickbalances.quickbalances]">
        <div
            v-if="showModal && !isWorkspace"
            :class="[s.modal, theme.quickbalances.modal]"
            :style="{ left: `${tooltipPosition.x}px`, top: `${this.tooltipPosition.y}px` }"
            class="balance-assets-modal"
            ref="modalContainer"
        >
            <BalanceModal
                v-if="balance"
                :balance="balance"
                :hide-modal="() => { showModal = false }"
            />
        </div>
        <table :class="[s.table, theme.table.table]">
            <thead>
            <tr>
                <th :class="[theme.table.headerText, theme.table.alignLeft]">{{ isAggregatedByPlacements ? 'Placement' : 'Currency' }}</th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">Amount</th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">Free</th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">Hold</th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">Value in {{ quotationAssetSymbol }}</th>
                <th width="35%" :class="[theme.table.headerText, theme.table.alignCenter]">Allocation</th>
                <th width="100px" :class="[theme.table.headerText, theme.table.alignRight]" v-if="showShortGraph">Amount Change</th>
                <th width="100px" :class="[theme.table.headerText, theme.table.alignRight]" v-if="showShortGraph">Change in {{ quotationAssetSymbol }}</th>
            </tr>
            </thead>
            <BalanceRow
                v-for="(balance, index) in balances"
                :key="`aggregatedBalance${index}`"
                :balance="balance"
                :show-short-graph="showShortGraph"
                :max-list-precision="assetPrecision"
                :is-aggregated-by-placements="isAggregatedByPlacements"
                :ref="`row${index}`"
                @show-modal="setModal($event)"
            />
        </table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import theme from 'Theme';
import BalanceModal from 'Modules/AccountPortfolio/components/BalanceModal.vue';
import { composedPath } from 'Lib/utils/eventPathChecker';

import BalanceRow from './BalanceRow.vue';

export default {
    name: 'BalancesList',
    components: {
        BalanceModal,
        BalanceRow,
    },
    props: {
        balances: {
            type: Array,
            default: () => [],
        },
        showShortGraph: {
            type: Boolean,
            default: false,
        },
        isAggregatedByPlacements: {
            type: Boolean,
            required: true,
        },
        isWorkspace: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            theme,
            showModal: false,
            balance: null,
            tooltipPosition: {
                x: 0,
                y: 0,
            },
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        assetPrecision() {
            return 10;
        },
    },
    methods: {
        // this method is needed to call it from the parent component
        closeTabs() {
            for (let i = 0; i < this.balances.length; i += 1) {
                this.$refs[`row${i}`].closeDetails();
            }
        },
        setModal(balance) {
            if (
                balance.placementName.toLowerCase() === 'single broker'
                || balance.placementName.toLowerCase() === 'otc market'
            ) {
                this.showModal = false;
                return;
            }

            if (!this.balance) {
                this.showModal = true;
            } else if (this.balance.placementName === balance.placementName
                && this.balance.assetSymbol === balance.assetSymbol
                && this.balance.blockchainName === balance.blockchainName) {
                this.showModal = !this.showModal;
            } else {
                this.showModal = true;
            }
            this.tooltipPosition.x = balance.x;
            this.tooltipPosition.y = balance.y;
            this.balance = balance;
        },
        closeModalOnWindowClick(e) {
            const path = composedPath(e.target);
            if (!path.some(({ classList }) => classList && (Array.from(classList).includes('balance-assets-modal') || Array.from(classList).includes('balance-assets-modal-toggle')))) {
                this.showModal = false;
            }
        },
    },
    mounted() {
        window.addEventListener('click', this.closeModalOnWindowClick);
    },
    beforeDestroy() {
        window.removeEventListener('click', this.closeModalOnWindowClick);
    },
};
</script>

<style lang="postcss" module="s">
.table {
    width: 100%;
    border-collapse: collapse;
}
.relative {
    position: relative;
}
.modal {
    position: absolute;
}
</style>
