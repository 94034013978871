<template>
    <div :class="s.row">
        <popper
            :trigger="totalScore === 0 ? 'clickToOpen' : 'hover'"
            :options="{
                placement: 'bottom',
                modifiers: { offset: { offset: '0, 5px' } },
            }"
            :class="[s.widthAuto, { [s.disabled]: totalScore === 0, [s.mrM]: !hideAllInfoTarget }]"
        >
            <div class="popper" :class="s.popper">
                <AllPlacementsBalancePopover :is-aggregated-by-placements="isAggregatedByPlacements" :placements="balances" />
            </div>
            <div v-show="!hideAllInfoTarget" slot="reference">
                <Icon icon="info" />
            </div>
        </popper>
        <PlacementBalanceBar
            v-for="(element, index) in balances"
            :key="isAggregatedByPlacements ? `${element.assetSymbol}${index}` : `${element.placement}${index}`"
            :balance="isAggregatedByPlacements ? element : element.balance"
            :placement="isAggregatedByPlacements ? element.assetSymbol : element.placement"
            :is-aggregated-by-placements="isAggregatedByPlacements"
            :total-score="totalScore"
        />
    </div>
</template>

<script>
import Icon from 'UI/Icon.vue';

import PlacementBalanceBar from './PlacementBalanceBar.vue';
import AllPlacementsBalancePopover from './AllPlacementsBalancePopover.vue';

export default {
    name: 'PlacementsBalances',
    components: {
        PlacementBalanceBar,
        AllPlacementsBalancePopover,
        Icon,
    },
    props: {
        placements: {
            type: [Object, Array],
            required: true,
        },
        totalScore: {
            type: Number,
            default: 0,
        },
        hideAllInfoTarget: {
            type: Boolean,
            default: false,
        },
        isAggregatedByPlacements: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        balances() {
            if (this.isAggregatedByPlacements) {
                const temp = this.placements;
                return temp.sort(({ placementName: aPlacement, totalEquivalent: a }, { placementName: bPlacement, totalEquivalent: b }) => {
                    if (aPlacement === 'Other') {
                        return 1;
                    }
                    if (bPlacement === 'Other') {
                        return -1;
                    }
                    return b - a;
                });
            }
            const result = [];
            Object.keys(this.placements).forEach((placement) => {
                if (this.placements[placement].totalEquivalent !== 0) {
                    const temp = {};
                    temp.placement = placement;
                    temp.balance = this.placements[placement];
                    result.push(temp);
                }
            });
            return result.sort((a, b) => {
                if (a.placement.indexOf('Single Broker') !== -1 && b.placement.indexOf('Single Broker') !== -1) {
                    return b.balance.free - a.balance.free;
                }
                if (a.placement.indexOf('Single Broker') !== -1 && b.placement.indexOf('Single Broker') === -1) {
                    return -1;
                }
                if (b.placement.indexOf('Single Broker') !== -1 && a.placement.indexOf('Single Broker') === -1) {
                    return 1;
                }
                return b.balance.free - a.balance.free;
            });
        },
    },
};
</script>

<style lang="postcss" module="s">
.row {
    display: flex;
    align-items: center;
    height: 43px;
    width: 100%;
}
.widthAuto {
    width: auto;
}
.mrM {
    margin-right: var(--m-m);
}
.disabled {
    opacity: .3;
}
.popper {
    background-color: var(--cl-white);
    border: 2px solid var(--cl-violet);
    border-radius: 8px;
    min-width: unset;
}
</style>
